
/* eslint-disable */
// Autogenerated
// -------------
// gulpfile.ts/wasm.ts --> generateBuildInfo

export const Build = {
    version: "0.73.7 (3be232bb7eac1f7a5b11976b24b16e30)",
    buildSeed: 1657512699192,
    "wdosbox.shared.wasm": {
        "size": 1477039,
        "gzSize": 506282
    },
    "wdosbox.shared.js": {
        "size": 143644,
        "gzSize": 36441
    },
    "wdosbox.wasm": {
        "size": 1464598,
        "gzSize": 502158
    },
    "wdosbox.js": {
        "size": 124362,
        "gzSize": 32522
    },
    "wlibzip.wasm": {
        "size": 112936,
        "gzSize": 54545
    },
    "wlibzip.js": {
        "size": 76373,
        "gzSize": 19886
    }
,
};
